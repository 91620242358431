import '../../styles/pages/fall-winter-2022/edge-of-the-universe.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2022-stories/more-stories"
import { Parallax } from 'react-parallax';
import { withPrefix } from 'gatsby';
import Link from "gatsby-plugin-transition-link/AniLink";
// import { BrowserView, MobileView } from 'react-device-detect';
import Icon from "../../components/icons";
import Vimeo from '@u-wave/react-vimeo';
import Modal from 'react-modal';
import { Preloader, Placeholder } from 'react-preloading-screen';
import { MagicSpinner } from "react-spinners-kit";
import { Scrollbars } from 'react-custom-scrollbars';



import closeIcon from '../../images/icons/close.svg';

import socialBanner from "../../images/fall-winter-2022/social-banners/edge-of-the-universe.jpg";


import Hero from "../../images/fall-winter-2022/edge-of-the-universe/hero.jpg";
import cosmicExplorer from "../../images/fall-winter-2022/edge-of-the-universe/cosmic-explorer.jpg";
import smithLovelaceRead from "../../images/fall-winter-2022/edge-of-the-universe/smith-lovelace-read.jpg";
import relatedStory from "../../images/fall-winter-2022/edge-of-the-universe/new-wave-of-future-scientists.jpg"
import gwpac from "../../images/fall-winter-2022/edge-of-the-universe/gwpac.jpg"


Modal.setAppElement('#___gatsby')


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'To the Edge of the Universe — and Beyond';
    var pageDesc = 'With the next-generation Cosmic Explorer observatory, Cal State Fullerton gravitational-wave scientists shift research focus toward future discoveries of the cosmos.';
    var slug = 'edge-of-the-universe';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2022 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />

          <link rel="stylesheet" href="https://use.typekit.net/awu6ynf.css"></link>
        </Helmet>

        <Preloader fadeDuration={500} style={{backgroundColor: '#111'}}>

        <div className='story-container'>
          <Parallax
          bgImage={Hero}
          bgImageAlt=""
          strength={200}
          className="parallax-hero animated fadeIn slower">
            <div className='text'>
              <h1 className="animated fadeInDown delay-2s">{pageTitle}</h1>
              <div className='wrap small'>
                <p className='animated fadeInDown delay-2s'>With the next-generation Cosmic Explorer observatory, Cal State Fullerton gravitational-wave scientists shift research focus toward future discoveries of the cosmos.</p>
                <span className="animated fadeInDown delay-2s byline">By Debra Cano Ramos</span>
              </div>
            </div>
            <div className='caption'>Artist’s illustration of two neutron stars merging and the light and gravitational waves produced by that merger.<br />Credit: Edward Anaya ’22 (B.F.A. art-entertainment art/animation)</div>
            <div className="tint" />
            <div className="height" />
          </Parallax>
          <div id="intro">
            <article className="wrap small">
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p><span className='intro-text'>Like Galileo’s first telescope to spy celestial objects and record discoveries</span>, Cal State Fullerton’s physicists are barely scratching the universe to find out more about mysteries of the cosmos through gravitational-wave observations.</p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                <blockquote>
                  <p>We’ve seen amazing things so far, but it's just the beginning.</p>
                </blockquote>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>These waves, which can offer insight into the evolution of the universe, are produced from extreme cataclysmic events like powerful and massive colliding black holes, binary neutron stars and black hole-neutron star systems. </p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>To extend humanity’s gravitational-wave view from the first nearby discoveries to across the history of the cosmos, the CSUF researchers and their students are now on the ground floor of designing the new Cosmic Explorer — the next-generation gravitational-wave observatory in the United States.</p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>“We have this incredible opportunity to be part of the United States’ next scientific megaproject in gravitational waves,” says Joshua Smith, professor of physics and Dan Black Director of Gravitational-Wave Physics and Astronomy. </p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>“We can help chart the course of the development and design of Cosmic Explorer — and contribute to some of the most fundamental scientific and technical challenges that will need to be addressed for Cosmic Explorer to succeed.” </p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>With 10 times the sensitivity of <a href="https://www.ligo.caltech.edu" target="_blank" rel="noreferrer">Advanced LIGO’s</a> two existing U.S. observatories, <a href="https://cosmicexplorer.org" target="_blank" rel="noreferrer">Cosmic Explorer</a> promises to peer deeply into the universe’s dark side, observe black holes and remnants of the first stars across cosmic time, measure space-time with unprecedented precision, and open a wide discovery aperture to the novel and unknown. </p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>“Through advances in technology, we can help the detectors to see even farther out into the universe and further back in time,” Smith adds.</p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>Since the thrilling <a href="https://news.fullerton.edu/2016/02/gravitational-waves-detected-100-years-after-einsteins-prediction/" target="_blank" rel="noreferrer">first detection</a> of gravitational waves in 2015, the physicists and their students — part of the international <a href="https://www.ligo.org" target="_blank" rel="noreferrer">LIGO</a> Scientific Collaboration — have played leading roles in gravitational-wave discoveries.</p> 
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>“We’ve seen amazing things so far, but it's just the beginning. We want to push our gravitational reach to the edge of the universe,” says Geoffrey Lovelace, professor of physics.</p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <h2 id='advancing-exploration'>Advancing Exploration<br />of the Cosmos</h2>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>With better sensitivity and higher precision than the existing observatories, Cosmic Explorer will provide a view of the early universe like NASA’s newly operational James Webb Space Telescope. The powerful telescope has dazzled the world with its spectacular first images of the universe. </p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>“Cosmic Explorer will see far enough across the universe to look back in time to the era when the first black holes and neutron stars had formed from the very first generation of stars in the universe, known as Population III stars,” Smith points out.</p>
              </ScrollAnimation>
  
  
            </article>
          </div>
          <figure>
            <img src={cosmicExplorer} alt="" />
            <figcaption>Artist’s impression of Cosmic Explorer. Credit: Edward Anaya ’22 (B.F.A. art-entertainment art/animation) and art major Virginia Kitchen.</figcaption>
          </figure>
          <article className="wrap small">
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“Its observations will tell us exactly when, and in what abundance, the first stars formed. But even more exciting, if Cosmic Explorer detects signals from before the first stars, it will tell us that so-called primordial black holes exist and were formed during the birth of the universe.”</p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>The LIGO (Laser Interferometer Gravitational-wave Observatory) and Virgo scientific collaborations have observed 90 gravitational-wave events from detectors in the United States and Italy since 2015. Over 100 years ago, Einstein’s theory of relativity predicted that gravitational waves — ripples in the fabric of space-time that travel at the speed of light — exist.</p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>For most of history, light, like from Galileo’s telescope, was the only tool that humans had to observe the universe beyond the solar system, Lovelace relays. The National Science Foundation-funded LIGO project gave humanity a new and fundamentally different tool to observe the universe in gravitational waves, caused by astronomical sources that are billions of light years away. </p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h2 id="new-horizon">A New Horizon<br />in Astronomy</h2>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <figure className='align-wide media-bg'>
              <img src={smithLovelaceRead} alt="" />
              <figcaption>Jocelyn Read, Joshua Smith, Geoffrey Lovelace</figcaption>
            </figure>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>Now, physicists Smith, Lovelace and Jocelyn Read are involved in various aspects of the Cosmic Explorer project, including at the forefront of efforts to identify suitable site locations for the two Cosmic Explorer observatory sites, organize the Cosmic Explorer Consortium of global researchers, and lead Cosmic Explorer’s computational science.</p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p><a href="https://news.fullerton.edu/2018/02/astrophysicist-strikes-gold/" target="_blank" rel="noreferrer">Read</a>, associate professor of physics and an internationally known astrophysicist, is looking forward to expanding knowledge of how massive stars live, die and create matter.</p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“Cosmic Explorer will act as an astrophysical-scale collider, measuring the gravitational waves rippling out from the impact and aftermath of neutron star mergers with incredible precision,” she says. “Mapping the occurrence of these mergers across cosmic time will help us understand how heavy elements have been forged over the history of our universe.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <figure className='align-wide media-bg'>
              <span><small>Warning: Video contains flashing lights</small></span>
              <video loop muted="muted" controls poster={withPrefix('/video/fall-winter-2022/merger.jpg')} >
                <source src={withPrefix('/video/fall-winter-2022/merger.webm')} type="video/webm; codecs=vp9,vorbis" />
                <source src={withPrefix('/video/fall-winter-2022/merger.mp4')} />
                <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/fall-winter-2022/merger.vtt')} />
              </video>
              <figcaption>Neutron stars collide. Credit: NASA's Goddard Space Flight Center/CI Lab</figcaption>
            </figure>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>Read was invited to help write the first Cosmic Explorer proposal, which later led to the $2.2 million <a href="https://dcc.cosmicexplorer.org/public/0163/P2100003/007/ce-horizon-study.pdf" target="_blank" rel="noreferrer">Cosmic Explorer Horizon Study</a>, funded by the National Science Foundation. </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <TenYears />

            <p>She convinced colleagues Lovelace and Smith — all three are founding faculty of the university’s Nicholas and Lee Begovich Center for Gravitational-Wave Physics and Astronomy — to join the effort. The gravitational-wave research, education and outreach center, known as <a href="https://physics.fullerton.edu/gwpac/" target="_blank" rel="noreferrer">GWPAC</a>, celebrated its 10th anniversary in September. </p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>The physicists are <a href="https://arxiv.org/abs/2109.09882" target="_blank" rel="noreferrer">co-authors</a> of the 2021 Cosmic Explorer Horizon Study, which envisions the science, technology, partnerships, timeline and estimated $2 billion cost of the project, planned to be built over the next decade. Other CSUF co-authors are former postdoctoral scholar <a href="https://news.fullerton.edu/2020/06/gravitational-wave-2019-discovery/" target="_blank" rel="noreferrer">Philippe Landry</a> and 2022 graduate <a href="https://news.fullerton.edu/2022/05/physics-grad-shoots-for-the-moon-lands-a-career-in-space-industry/" target="_blank" rel="noreferrer">Alex Gruson</a> (M.S. physics). Partners in the project are Caltech, MIT, Syracuse University and Pennsylvania State University.</p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>As the convener of the Cosmic Explorer Consortium, which is intended to organize and represent the broader Cosmic Explorer community, Read is working with fellow scientists to develop the structure and consortium objectives. She also is recruiting collaborators to assess Cosmic Explorer's science potential, and contribute the research and development needed to make the project a reality. The first observations are projected to begin in 2035. </p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>Smith, who specializes in optics and characterization of gravitational-wave detectors, is the director of instruments and observatories for Cosmic Explorer. In this role, he is leading the effort to identify and evaluate suitable observatory locations and overseeing the success of the project’s instruments, facilities and site planning. </p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>Lovelace, who uses <a href="https://news.fullerton.edu/2017/02/glovelace-career-award/" target="_blank" rel="noreferrer">numerical relativity</a> on a supercomputer to simulate the merging of black holes as sources of gravitational waves, is the lead computer scientist for the project. His responsibilities include scoping out the computational requirements and helping to craft the plan for how Cosmic Explorer will manage its data, such as who gets access and how it will be stored. He also is contributing to efforts to ensure that supercomputer calculations modeling gravitational wave sources are sufficiently accurate for Cosmic Explorer.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <aside className="related-stories align-outside">
              <h2>Related Story</h2>
              <Link
              className="related-link"
              cover
              bg="#FF7900"
              direction="up"
              duration={1}
              to="/fall-winter-2022/new-wave-of-future-scientists/">
                <img src={relatedStory} alt="" className="link-image" />
                <span className="link-text">A New Wave of Future Scientists</span>
              </Link>
            </aside>
            
            <p>As the physicists shift their research focus to support Cosmic Explorer, they are eager to make more trailblazing discoveries and gain a deeper understanding of the densest matter in the universe through gravitational-wave observations.</p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“I'm forever awed that the idea of gravitational-wave astrophysics has become a reality and that we are only beginning to explore this rich new perspective on our universe,” Read shares.</p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“I'm looking forward to new discoveries, like wobbly neutron stars in our own galaxy, an unanticipated wave pattern that could signify new physics, or an unexpected and out-of-this-world source of gravitational waves.” <span className='end-mark' /></p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p className="learn-more" style={{marginTop: '150px'}}>Support the College of Natural Sciences and Mathematics<br /><a href="https://campaign.fullerton.edu/nsm" target="_blank">campaign.fullerton.edu/nsm</a></p>
            </ScrollAnimation>

  
          </article>
        </div>
        <MoreStories slug={slug} />

        <Placeholder>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <MagicSpinner size="30" color="#fff" />
            <span style={{
              fontSize: '.7em',
              display: 'block', 
              marginTop: '30px', 
              color: '#ccc', 
              textTransform: 'uppercase',
              textAlign: 'center',
              letterSpacing: '5px'
            }}>
              Loading
            </span>
          </div>
				</Placeholder>
			</Preloader>
      </Layout>
    );
  }
}

export class TenYears extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    return (
      <>
      <aside className="related-stories align-outside left">
        <button className="related-link button ten-years-button" onClick={this.openModal}>
          <h2>A Decade of Riding Gravitational-Wave Success</h2>
          <img src={gwpac} alt="" className="link-image" />
        </button>
      </aside>
      <Modal
        closeTimeoutMS={400}
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel="Watch Video"
        className="video-modal ten-year"
        overlayClassName="overlay"
        shouldCloseOnOverlayClick={true}
      >
        <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
        <div className='modal-flex'>
          <Vimeo
            video={737650222}
            responsive
            showTitle={false}
            showByline={false}
            showPortrait={false}
            autoplay
            onEnd={this.closeModal}
            style={{backgroundColor: 'black'}}
            className="video"
          />
          <div className='text'>
            <Scrollbars
              className="scrollbars"
              renderView={props => <div {...props} className="scrollbar-view"/>}
              renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
              style={{ width: '100%', height: '100%' }}>
  
              <h2>A Decade of Riding Gravitational-Wave Success</h2>
      
              <ul>
                <li><strong>Nicholas and Lee Begovich Center for Gravitational-Wave Physics and Astronomy (<a href="https://physics.fullerton.edu/gwpac/" target="_blank" rel="noreferrer">GWPAC</a>):</strong> Established on Sept. 28, 2012; <a href="https://physics.fullerton.edu/gwpac/news/highlights" target="_blank" rel="noreferrer">10-year anniversary</a> celebrated on Sept. 30, with scientific meeting, and Oct. 1 alumni event.</li>
                <li><strong>GWPAC mission:</strong> Gravitational-wave research, education and outreach</li>
                <li><strong>GWPAC renamed:</strong> Nicholas and Lee Begovich Center for Gravitational-Wave Physics and Astronomy in honor of the late <a href="https://news.fullerton.edu/2020/02/campaign-begovich-giving/" target="_blank" rel="noreferrer">Nicholas Begovich</a> and his wife, Lee, for their $10 million gift to the university, announced Feb. 29, 2020. ($7 million for gravitational-wave research and $3 million for interdisciplinary research in engineering and computer science.)</li>
                <li><strong>First gravitational-wave discovery:</strong> Sept. 14, 2015, from merging <a href="https://news.fullerton.edu/2016/02/gravitational-waves-detected-100-years-after-einsteins-prediction/" target="_blank" rel="noreferrer">black holes</a>, which  confirmed a major prediction of Albert Einstein’s 1915 general theory of relativity. LIGO announced the discovery on Feb. 11, 2016, which led to the 2017 Nobel Prize in Physics.</li>
                <li><strong>First-ever discovery of gravitational waves from a binary neutron star system:</strong> Announced Aug. 17, 2017, from a <a href="https://news.fullerton.edu/2018/02/astrophysicist-strikes-gold/" target="_blank" rel="noreferrer">galaxy</a> 130 million light years away from Earth.</li>
                <li><strong>Discoveries:</strong> To date, the LIGO and Virgo (Italy) scientific collaborations have observed 90 gravitational wave events, including binary black holes, binary neutron stars and black hole neutron star systems. </li>
                <li><strong>Laser Interferometer Gravitational-wave Observatory (LIGO) detectors:</strong> After over two years of upgrades and maintenance, U.S. observatories in Livingston, Louisiana, and Hanford, Washington, will resume its <a href="https://www.ligo.caltech.edu/news/ligo20220617" target="_blank" rel="noreferrer">next observing run</a> in March 2023 (the fourth since 2015). The two detectors will be joined by Virgo in Italy and KAGRA in Japan. </li>
                <li><strong>100 publications</strong> in peer-reviewed scientific journals; many with student co-authors</li>
                <li><strong>$8 million</strong> in federal funding, including from the National Science Foundation</li>
                <li><strong>$7.5 million</strong> in philanthropic support, including from the late <a href="https://news.fullerton.edu/2020/02/campaign-begovich-giving/" target="_blank" rel="noreferrer">Nicholas Begovich</a> and his wife, Lee; physics alumnus <a href="https://news.fullerton.edu/2016/04/dan-black-gift/" target="_blank" rel="noreferrer">Dan Black</a>; and alumna Nancy Goodhue-McWilliams</li>
              </ul>
      
              <h2>What’s Next: Cosmic Explorer</h2>
              <p>Cosmic Explorer, the U.S.’s next-generation gravitational-wave observatory, features two observatories with 10 times the sensitivity of Advanced LIGO’s two existing U.S. observatories.</p>
      
              <ul>
                <li><strong>2015</strong> – Initial development</li>
                <li><strong>2020s</strong> – <a href="https://dcc.cosmicexplorer.org/public/0163/P2100003/007/ce-horizon-study.pdf" target="_blank" rel="noreferrer">Horizon Study</a> (science and technology objectives, partnerships, timeline, project cost, U.S. site search, research and design); A $211,283 National Science Foundation grant funded the study, a collaboration between CSUF, MIT, Syracuse University and Pennsylvania State University. </li>
                <li><strong>2025-35</strong> – Construction</li>
                <li><strong>2035</strong> – First observations</li>
              </ul>

              <h2>CSUF Gravitational-Wave Researchers</h2>

              <ul>
                <li><strong>Joshua Smith</strong>, professor of physics and Dan Black Director of Gravitational-Wave Physics and Astronomy, specializing in optics and characterization of the detectors</li>
                <li><strong>Jocelyn Read</strong>, associate professor of physics, neutron star astrophysicist</li>
                <li><strong>Geoffrey Lovelace</strong>, professor of physics, numerical relativist</li>
                <li><strong>Alfonso Agnew</strong>, chair and professor of mathematics, mathematical relativist</li>
                <li><strong>Gabriel Bonilla</strong>, postdoctoral scholar, Ph.D. in applied physics, Cornell University 2021 (three postdoctoral scholars to date)</li>
                <li><strong>Joseph Areeda</strong>, computation specialist</li>
              </ul>
      
              <button onClick={this.closeModal} id="closeButton2"><img src={closeIcon} alt="" /> Close</button>
            </Scrollbars>
          </div>
        </div>


      </Modal>
      </>
    );
  }
}